<template>
  <div class="story" v-if="story">
    <div class="bullets">
      <div
        class="bullet"
        :class="{'active':active==index, 'filled':index<active}"
        v-for="(item, index) in [...story.imgs]"
        :key="index"
      ></div>
    </div>
    <swiper
      :options="swiperOptions"
      @init="slideChange()"
      @slideNextTransitionStart="slideNextTransitionStart()"
      @slidePrevTransitionStart="slidePrevTransitionStart()"
      @reachEnd="reachEnd()"
    >
      <swiper-slide
        v-for="(item, index) in [...story.imgs]"
        :key="index"
        :data-story="index"
      >
        <img :src="item.image" alt />
        <div class="content">
          <p
            v-for="(tag, idx) in [...story.tags]"
            :key="idx"
            class="content-events"
          >
            {{ tag.name }}
          </p>
          <h2 class="content-title">
            {{ item.title }}
          </h2>
          <p class="content-description">
            {{ item.description }}
          </p>
          <detailed-button
            v-if="onMap"
            :btn-map="'map'"
            :btn-text="'На карте'"
          />
        </div>
      </swiper-slide>
    </swiper>
    <swipe
      v-if="active === 0"
      class="swipe"
    />
  </div>
</template>


<script>
/*
  Интервал смены слайда устанавливается в трех местах:
  -- data: swiperOptions.delay
  -- methods: reachEnd
  -- style: &.active:after
*/

import { mapGetters } from "vuex";
import Swipe from "@/components/Icons/Swipe";
import DetailedButton from "@/components/Parts/DetailedButton";

export default {
  components: {
    Swipe,
    DetailedButton
  },
  data() {
    return {
      onMap: false,
      story: null,
      active: -1,
      swiperOptions: {
        slidesPerView: 1,

        autoplay: {
          delay: 15000,
          disableOnInteraction: false
        }
      },
      example: [
        {
          name: 'yellow'
        },
        {
          name: 'black'
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["stories"]),
    tags() {
      return this.example
    }
  },

  mounted() {
    this.getStory();
  },
  watch: {
    story() {
      this.$nextTick(() => {
        this.active++;
      });
    }
  },

  methods: {
    async getStory() {
        await api.getStory(this.$route.params.id).then(res => {
          this.story = res.data;
        });
    },
    slideNextTransitionStart() {
      this.active++;
    },
    slidePrevTransitionStart() {
      this.active--;
    },
    reachEnd() {
      setTimeout(() => {
        console.log(this.active);
        if (
          this.$route.name == "StoryScreen" &&
          this.active == this.story.imgs.length - 1
        )
          this.$router.back();
      }, 15000);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.story {
  position: relative;

  .swiper-slide {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 1192px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.6;
    }

    .content {
      position: absolute;
      top: 0;
      padding: 261px 158px 172px 74px;
      text-align: left;

      .content-events {
        display: inline-block;
        font-size: 30px;
        padding: 16px 32px;
        margin-right: 15px;
        background-color: #314fc6;
        border-radius: 10px;
      }

      .content-title {
        margin-top: 40px;
        color: rgba(#fff, 0.95);
        font-weight: 800;
        font-size: 80px;
        line-height: 88px;
      }

      .content-description {
        color: rgba(#fff, 0.95);
        font-weight: 500;
        font-size: 45px;
        line-height: 57px;
      }
    }
  }

  .swipe {
    position: absolute;
    z-index: 100;
    bottom: 56px;
    right: 32px;
    width: 187px;
    height: 162px;
  }

  .bullets {
    position: absolute;
    display: flex;
    width: 1016px;
    top: 40px;
    left: 32px;
    z-index: 2;

    .bullet {
      display: block;
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 10px;
      border-radius: 5px;
      background: rgba(#fff, 0.46);
      margin-right: 16px;
      transition: 0.15s linear;

      &:last-child {
        margin-right: 0;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: auto;
        margin: auto;
        width: 0;
        height: 100%;
        background: $color_blue_2;
        z-index: 2;
        transition: 0s linear;
        margin: 0;
      }

      &.active:after {
        width: 100%;
        transition: 15s linear;
      }

      &.filled {
        background: $color_blue_2;
      }
    }
  }
}
</style>
